import {Link,useLocation} from 'react-router-dom';
import {colapse} from '../shared.js'
import '../css/Header.css';
import { HiBell } from "react-icons/hi";
import {useEffect,useState} from 'react'


function Header({address,connectNami,connectLace,connectVespr,connectEternl,connectEternlMobile,emptyAllPunks}) {


  const { hash, pathname, search } = useLocation();
  const [notifications, setNotifications] = useState([]);


  useEffect(() => {
    if (address)
      window.$.ajax({
        url: '/getNotifications',
        type: 'GET',
        dataType: "json",
        data: {wallet: address},
        success: function (data) {
          setNotifications(data);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
            window.location='/login';
        },
      });
  }, [address]);


  function markAsRead(){
    if (getNewNotifications()>0)
      window.$.ajax({
        url: '/readNotifications',
        type: 'GET',
        dataType: "json",
        data: {wallet: address},
        success: function (data) {
          console.log(data);
          for (const notiofication of notifications)
            notiofication.read=true;
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
          if (jqXHR.status==401)
            window.location='/login';
        },
      });
  }
  function getNewNotifications(){
    return notifications.filter((a) => !a.read).length;
  }


  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 border-bottom">
        <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-auto link-body-emphasis text-decoration-none">
          <span className="fs-4">ADAPunks</span>
        </Link>

        <div className="dropdown d-md-none d-md-block position-relative" style={{marginLeft: '5px', marginTop: '10px'}}>
          {getNewNotifications()>0 && 
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {getNewNotifications()}
            </span>
          }
          <HiBell className="dropdown-toggle " onClick={markAsRead} data-bs-toggle="dropdown" style={{cursor:'pointer',color:'var(--bs-link-color)',width:'25px',height:'25px'}}/>
          <div className="dropdown-menu  px-3" style={{width:'250px'}}>
            {notifications.length==0 &&
              <div>Empty</div>
            }
            {
              notifications.map((notification) =>
                <div className='fs-6 d-flex my-3' >
                  <img class="rounded-2 me-1" style={{maxWidth:'45px',maxHeight:'45px'}} src={'/nftImg?id='+notification.tokenId}></img>
                  <div className="position-relative">
                    {!notification.read && 
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        *
                      </span>
                    }
                    <div className='fw-bold'>{notification.tokenName}</div>
                    <div>{notification.msg}</div>
                  </div>
                </div>
              ) 
            }
          </div>
        </div>
        <div className="dropdown d-md-none" style={{marginLeft: '15px', marginTop: '5px'}}>
          <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {address!=null && window.$.cookie('wallet') != null && <img style={{maxWidth: '20px',marginRight:'15px'}} src={'/imgs/'+window.$.cookie('wallet')+'.png'}/>}
            {(address!=null?colapse(address):"Connect")}
          </button>
          <ul className="dropdown-menu">
            {address!=null && <li><Link to="/mywallet/punks" className={"dropdown-item "}><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/wallet.png'/>My wallet
              {/* <span class=" translate-middle badge rounded-pill bg-danger">
                1
                <span class="visually-hidden">unread messages</span>
              </span> */}
            </Link></li>}
              <li><a className='dropdown-item' onClick={connectLace} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/lace.png'/>Lace</a></li>
              <li><a className='dropdown-item' onClick={connectNami} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/nami.png'/>Nami</a></li>
              <li><a className='dropdown-item' onClick={connectVespr} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/vespr.png'/>Vespr</a></li>
              <li><a className='dropdown-item' onClick={connectEternl} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/eternl.png'/>Eternl</a></li>
              <li><a className='dropdown-item' onClick={connectEternlMobile} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/eternl.png'/>Eternl Mobile</a></li>
          </ul>
        </div>

        <ul className="nav">
          {/* <li className="nav-item"><Link to="/fight" className="nav-link SuperLink">Fight</Link></li> */}
          <li className="nav-item"><Link to="/buy" className={"nav-link SuperLink"+(pathname.endsWith('buy')?' link-body-emphasis text-decoration-underline':'')}>Buy</Link></li>
          <li className="nav-item"><Link to="/offers" className={"nav-link SuperLink "+(pathname.endsWith('offers') && !pathname.includes('mywallet') && !pathname.includes('lending')?' link-body-emphasis text-decoration-underline':'')}>Offers</Link></li>
          <li className="nav-item"><Link to="/lending/borrows" className={"rounded position-relative nav-link SuperLink "+(pathname.includes('lending')?' link-body-emphasis text-decoration-underline':'')}>Lending</Link>
          </li>

          <li className="nav-item"><Link to="/adapunks" className={"nav-link SuperLink"+(pathname.endsWith('adapunks')?' link-body-emphasis text-decoration-underline':'')}>Punks</Link></li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              History
            </a>
            <ul className="dropdown-menu">
              {/* <li><Link className="dropdown-item nav-link SuperLink" to="/listFights">Fights</Link></li> */}
              <li><Link className="dropdown-item nav-link SuperLink" to="/sales">Sales</Link></li>
              <li><Link className="dropdown-item nav-link SuperLink" to="/topSales">Top sales</Link></li>
              {/* <li><Link className="dropdown-item nav-link SuperLink" to="/listSales">Local sales</Link></li> */}
              <li><Link className="dropdown-item nav-link SuperLink" to="/listLoans">Loans</Link></li>
            </ul>
          </li>
          <li className="nav-item">

          </li>
          {/* <li className="nav-item"><button className="btn btn-primary" type="button" id="mint">Mint</button></li> */}
        </ul>


        <div className="dropdown d-none d-md-block position-relative" style={{marginLeft: '5px', marginTop: '10px'}}>
          {getNewNotifications()>0 && 
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {getNewNotifications()}
            </span>
          }
          <HiBell className="dropdown-toggle " onClick={markAsRead} data-bs-toggle="dropdown" style={{cursor:'pointer',color:'var(--bs-link-color)',width:'25px',height:'25px'}}/>
          <div className="dropdown-menu  px-3" style={{width:'250px'}}>
            {notifications.length==0 &&
              <div>Empty</div>
            }
            {
              notifications.map((notification) =>
                <div className='fs-6 d-flex my-3' >
                  <img class="rounded-2 me-1" style={{maxWidth:'45px',maxHeight:'45px'}} src={'/nftImg?id='+notification.tokenId}></img>
                  <div className="position-relative">
                    {!notification.read && 
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        *
                      </span>
                    }
                    <div className='fw-bold'>{notification.tokenName}</div>
                    <div>{notification.msg}</div>
                  </div>
                </div>
              ) 
            }
          </div>
        </div>
        <div className="dropdown d-none d-md-block" style={{marginLeft: '15px', marginTop: '5px'}}>
          <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {address!=null && window.$.cookie('wallet') != null && <img style={{maxWidth: '20px',marginRight:'15px'}} src={'/imgs/'+window.$.cookie('wallet')+'.png'}/>}
            {(address!=null?colapse(address):"Connect")}
          </button>
          <ul className="dropdown-menu">
            {address!=null && <li><Link to="/mywallet/punks" className={"dropdown-item "}><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/wallet.png'/>My wallet
              {/* <span class=" translate-middle badge rounded-pill bg-danger">
                1
                <span class="visually-hidden">unread messages</span>
              </span> */}
            </Link></li>}
              <li><a className='dropdown-item' onClick={connectLace} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/lace.png'/>Lace</a></li>
              <li><a className='dropdown-item' onClick={connectNami} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/nami.png'/>Nami</a></li>
              <li><a className='dropdown-item' onClick={connectVespr} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/vespr.png'/>Vespr</a></li>
              <li><a className='dropdown-item' onClick={connectEternl} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/eternl.png'/>Eternl</a></li>
              <li><a className='dropdown-item' onClick={connectEternlMobile} href='javascript:void(0)'><img style={{maxWidth: '20px',marginRight:'15px'}} src='/imgs/eternl.png'/>Eternl Mobile</a></li>
          </ul>
        </div>
      </header>
    </div>
  );
}

export default Header;